import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaBoxOpen,
  FaInfoCircle,
  FaEnvelope,
  FaBars,
} from "react-icons/fa";

const Header = ({ scrollY }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { name: "Ana Sayfa", path: "/", icon: FaHome },
    { name: "Ürün Galerisi", path: "/products", icon: FaBoxOpen },
    { name: "Hakkımızda", path: "/about", icon: FaInfoCircle },
    { name: "İletişim", path: "/contact", icon: FaEnvelope },
  ];

  return (
    <>
      <header
        className={`fixed w-full z-50 transition-all duration-300 ${
          scrollY > 50 ? "bg-white shadow-sm" : "bg-transparent"
        }`}
      >
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img
              src="/assets/logo3.png"
              alt="JungleFoam Logo"
              className="h-12 sm:h-16 md:h-20 w-auto" // Responsive logo size
            />
          </Link>
          <nav className="hidden md:block">
            <ul className="flex space-x-8">
              {navItems.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.path}
                    className={`text-lg font-medium hover:text-green-600 transition-colors duration-300 ${
                      scrollY > 50 ? "text-gray-800" : "text-gray-900"
                    } flex items-center`}
                  >
                    <item.icon className="mr-2" />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden text-gray-800 focus:outline-none"
          >
            <FaBars className="w-6 h-6" />
          </button>
        </div>
        {isOpen && (
          <div className="md:hidden bg-white shadow-md">
            <ul className="px-4 py-2">
              {navItems.map((item) => (
                <li key={item.name} className="py-2">
                  <Link
                    to={item.path}
                    className="text-lg font-medium text-gray-800 hover:text-green-600 transition-colors duration-300 flex items-center"
                    onClick={() => setIsOpen(false)}
                  >
                    <item.icon className="mr-2" />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </header>
      {/* Spacer div to prevent content from being hidden under the header */}
      <div className="h-20 md:h-28"></div>
    </>
  );
};

export default Header;