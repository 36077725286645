import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

const FooterLink = React.memo(({ to, children }) => (
  <Link to={to} className="text-gray-300 hover:text-white transition-colors duration-300">
    {children}
  </Link>
));

const SocialIcon = React.memo(({ href, icon }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors duration-300">
    {icon}
  </a>
));

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    // Normalde burada e-postayı sunucunuza gönderirsiniz
    console.log(`Abone olunan e-posta: ${email}`);
    setSubscribed(true);
    setEmail('');
  }, [email]);

  return (
    <div>
      <h3 className="text-white text-lg font-semibold mb-4">Bültenimize Abone Olun</h3>
      {subscribed ? (
        <p className="text-green-400">Abone olduğunuz için teşekkürler!</p>
      ) : (
        <form onSubmit={handleSubmit} className="flex">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-posta adresinizi girin"
            className="bg-gray-700 text-white px-4 py-2 rounded-l-md focus:outline-none focus:ring-2 focus:ring-green-500"
            required
          />
          <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded-r-md hover:bg-green-600 transition-colors duration-300">
            Abone Ol
          </button>
        </form>
      )}
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h2 className="text-2xl font-bold mb-4">Yayla Sünger</h2>
            <p className="text-gray-300">Sürdürülebilir bir gelecek için yenilikçi rebond sünger çözümleri.</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Hızlı Bağlantılar</h3>
            <ul className="space-y-2">
              {[
                { name: 'Ana Sayfa', path: '/' },
                { name: 'Ürünler', path: '/urunler' },
                { name: 'Hakkımızda', path: '/hakkimizda' },
                { name: 'İletişim', path: '/iletisim' }
              ].map((item) => (
                <li key={item.name}>
                  <FooterLink to={item.path}>{item.name}</FooterLink>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Bizi Takip Edin</h3>
            <div className="flex space-x-4">
              <SocialIcon href="#" icon={<i className="fab fa-facebook-f"></i>} />
              <SocialIcon href="#" icon={<i className="fab fa-twitter"></i>} />
              <SocialIcon href="#" icon={<i className="fab fa-linkedin-in"></i>} />
              <SocialIcon href="#" icon={<i className="fab fa-instagram"></i>} />
            </div>
          </div>
          <Newsletter />
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-300">
          <p>&copy; {new Date().getFullYear()} Yayla Malzemecilik Ltd. Şti. Tüm hakları saklıdır.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;